import {TIMER_STATUS, ORDERS_LIST, LOGIN_STORE, ORDER_ITEMS_LIST, ORDER_STATUS_LIST, CANCEL_ORDER_REASONS, DELIVERY_BOYS, UPDATE_ORDER, STATES_LIST, STORES_LIST, FILTERED_ORDERS, UPDATE_LOADER, UPDATE_TIMER } from './orderType'
import Api from '../../components/Api';
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
toast.configure();

let timer: any;
export const luanchTimer = (history: any) => {
    return function (dispatch: any) {
        dispatch({
            type: TIMER_STATUS,
            payload: true
        })
        timer = setInterval(() => {
            //history.go(0)
            let obj = { days: 0 }
            dispatch(ordersList(obj))
        }, 30000);
    }
}
export const stopTimer = () => {
    return function (dispatch: any) {
        dispatch({
            type: TIMER_STATUS,
            payload: false
        });
        clearInterval(timer)
    }
}

export const filterOrdersList = (filteredArr: any) => {
    return function (dispatch: any) {

        dispatch({
            type: FILTERED_ORDERS,
            payload: filteredArr,
            emptyFilterOrder: filteredArr.length > 0 ? false : true

        })
    }
}

export const getSadkaInvoice =(orderId: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/vatinvoicehungerstation/${orderId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response) => {
            if (response.data.success) {
                setTimeout(() => {
                    window.location.href = "/orders"
                }, 3000);
                toast.info(response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            }
            if (response.data.success == false) {
                setTimeout(() => {
                    window.location.href = "/orders"
                }, 2000);

                toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            }
        }).catch(err => {
            if (err.response) {
                
            } else {
                alert(err.message)
            }
        });
    }
}
export const searchOrder = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/search_order/${orderId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let recieved = 0;
                    let kitchen = 0;
                    let readyOrder = 0;
                    let completed = 0;
                    let failedIntg = 0;
                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        response.data.successResponse.forEach((element:any) => {
                            element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                        })
                        const obj = response.data.successResponse[i];
                        if (obj.order_status_code === 1) {
                            console.log("if (obj.order_status_code === 1) {",recieved);
                            
                            recieved += 1;
                        } if (obj.order_status_code === 2) {
                            console.log("if (obj.order_status_code === 1) {",kitchen);
                            kitchen += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                            console.log("} if (obj.order_status_code === 3 || obj.order_status_code === 8) {",readyOrder);
                            readyOrder += 1;
                        } if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                            console.log("} if (obj.order_status_code === 4 || obj.order_status_code === 5) {",completed);
                            
                            completed += 1;
                        } if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number === '') {
                            console.log("} if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number !== '') {",failedIntg);
                            
                            failedIntg += 1;
                        }
                    }
                    // response.data.successResponse.map((obj: any) => {
                    //     if (obj.order_status_code === 1) {
                    //         return recieved += 1;
                    //     } if (obj.order_status_code === 2) {
                    //         return kitchen += 1;
                    //     } if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                    //         return readyOrder += 1;
                    //     } if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                    //         return completed += 1;
                    //     } if ((obj.order_status_code === 4 || obj.order_status_code === 5) && obj.check_number === '') {
                    //         return failedIntg += 1;
                    //     }
                    // })
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        kitchenCount: kitchen,
                        dayVal: "",
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                    })
                    // get drivers
                     dispatch(deliveryBoysList(response.data.successResponse));
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        avg: 0,
                        kitchenCount: 0,
                        dayVal: "",
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const ordersList = (Obj: any, datetime?: any) => {
    return function (dispatch: any) {
        if (datetime) {
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        let arr:any=[]
        dispatch(storesList());
        Api.post(`store/orders/${store.store_id}`, Obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    arr=response.data.successResponse;
                    console.log("getTheData", arr);
                    // response.data.successResponse.map((obj1:any)=>{
                    //     if(obj1.order_status_code != 1){
                    //         arr.push(obj1)  
                    //     }
                    // })
                    let recieved = 0;
                    let kitchen = 0;
                    let readyOrder = 0;
                    let completed = 0;
                    // console.log("data-findout", arr);
                    arr.map((obj: any) => {
                        if (obj.order_status_code === 1) {
                            return recieved += 1;
                        } else if (obj.order_status_code === 2) {
                            return kitchen += 1;
                        } else if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                            return readyOrder += 1;
                        } else if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                            return completed += 1;
                        }
                    })
                    arr.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = arr[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: arr,
                        avg: avg,
                        kitchenCount: kitchen,
                        dayVal: Obj,
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        completedOrderCount: completed,
                        isLoading: false,
                        isTimerUpdate: false
                    })
                    dispatch(deliveryBoysList(arr))
                    sessionStorage.setItem('prevLength', arr.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        kitchenCount: 0,
                        dayVal: Obj,
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        completedOrderCount: 0,
                        isLoading: false,
                        isTimerUpdate: false
                    })
                    dispatch({
                        type: UPDATE_TIMER,
                        isTimerUpdate: false
                    })
                    console.log(err.response.data.message)
                } else {
                    console.log(err.message)
                }
            });
    }
}

export const unresolvedordersList = (Obj: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        dispatch(storesList());
        Api.post(`store/unresolvedorders/${store.store_id}`, Obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let recieved = 0;
                    let kitchen = 0;
                    let readyOrder = 0;
                    let completed = 0;
                    let confirmed = 0;
                    response.data.successResponse.map((obj: any) => {
                        if (obj.order_status_code === 1) {
                            return recieved += 1;
                        } else if (obj.order_status_code === 2) {
                            return kitchen += 1;
                        } else if (obj.order_status_code === 3 || obj.order_status_code === 8) {
                            return readyOrder += 1;
                        } else if (obj.order_status_code === 4 || obj.order_status_code === 5) {
                            return completed += 1;
                        }
                        else if (obj.order_status_code === 7) {
                            return confirmed += 1;
                        }
                    })
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        kitchenCount: kitchen,
                        dayVal: Obj,
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        completedOrderCount: completed,
                        confirmedOrderCount: confirmed
                    })
                    dispatch(deliveryBoysList(response.data.successResponse))
                    sessionStorage.setItem('prevLength', response.data.successResponse.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        kitchenCount: 0,
                        dayVal: Obj,
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        completedOrderCount: 0,
                        confirmedOrderCount: 0
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

// export const orderNewLength = (newLength: any) => {
//     return function (dispatch: any) {
//         dispatch({
//             type: ORDERS_NEW_LENGTH,
//             newOrdersLength: newLength
//         })
//     }
// }
export const showorderItems = (order_id: any, orders: any, orderingChannel:any) => {
    let channel = orderingChannel;
    if(orderingChannel === 'call center'){
        channel = 'callcenter'
    }
    else{
        channel = orderingChannel;
    }
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (order_id === "") {
            dispatch({
                type: ORDER_ITEMS_LIST,
                payload: []
            })
        } else {
            Api.get(`/admin/order_items/${order_id}/${channel}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        let order = orders.find((obj: any) => {
                            return obj.order_id == order_id
                        })
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: response.data.successResponse,
                            orderDetail: order
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const cancelOrdersReasons = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'cancel order'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CANCEL_ORDER_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const orderStatusList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/order_status', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDER_STATUS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deliveryBoysList = (orders: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/Drivers/getActiveDrivers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((driversData) => {
                if (driversData.data.success) {
                    // To lock local rider after one order dispatched
                    // driversData.data.successResponse.map((obj: any) => {
                    //     if (obj.goPanda == "false") {
                    //         orders.map((order: any) => {
                    //             if (obj.id == order.delivery_boy && (order.order_status_code == 1 || order.order_status_code == 2 || order.order_status_code == 3 || order.order_status_code == 8)) {
                    //                 obj.order_id = order.order_id;
                    //             }
                    //         })
                    //     }
                    // })
                    dispatch({
                        type: DELIVERY_BOYS,
                        payload: driversData.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const updateOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_ORDER,
            payload: true
        })
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/update_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    if (data.delivery_boy) {
                        toast.info("Driver Saved Succesfully!", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    } else {
                        if (response.data.error == false) {
                            toast.error(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        } else {
                            toast.info(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        }
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    }
                    dispatch(ordersList({ days: 0 }))

                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please select the driver";
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: UPDATE_ORDER,
                        payload: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateunresolvedOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_ORDER,
            payload: true
        })
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/update_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    if (data.delivery_boy) {
                        toast.info("Driver Saved Succesfully!", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    } else {
                        if (response.data.error == false) {
                            toast.error(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        } else {
                            toast.info(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        }
                        dispatch({
                            type: UPDATE_ORDER,
                            payload: false
                        })
                    }
                    dispatch(unresolvedordersList({ days: 1 }))

                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please select the driver";
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: UPDATE_ORDER,
                        payload: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}

export const UnsuspendStore = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/store/store_unsuspend/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = '/orders'
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const updatefutureOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/update_future_order/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    history.go(0);
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    // dispatch({
                    //     type: UPDATE_ORDER,
                    //     isUpdated: false,
                    //     payload: error
                    // })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const statesList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/states', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STATES_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const storesList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/stores', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STORES_LIST,
                        payload: response.data.successResponse
                    })
                    dispatch({
                        type: LOGIN_STORE,
                        loginStore: response.data.successResponse[0]
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}