import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Topbar from '../components/topbar'
import Sidebar from '../components/sidebar'
import Footer from '../components/footer/main'
import { secretKey } from '../secret'
import { connect } from 'react-redux'
import { logoutUser, homeCounter, recentOrders, monthlyOrdersForLine, monthlySalesForBar, customersList } from '../redux'
import { HomeProps } from '../interfaces/home';
import { currency } from '../client-config';
import moment from 'moment';
import { toast } from "react-toastify";
import { API_URL } from '../client-config';
import "react-toastify/dist/ReactToastify.css";
import { DougnutChartChannel, LineChartData, PieChartSOS } from '../redux/actions/homeAction';
toast.configure();
Chart.plugins.register(ChartDataLabels);


class LineChart extends Component<{ data: any[] }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let completedOrders = [];
        let totalSales = [];
        let weekDays: any = [];
        for (let i = 0; i < nextProps.data.length; i++) {
            let date = nextProps.data[i].date_created.split("T")
            weekDays.push(moment(date[0]).local().format("DD MMM"))
        }
        for (let i = 0; i < nextProps.data.length; i++) {
            completedOrders[i] = nextProps.data[i].completeOrders;
            totalSales[i] = nextProps.data[i].totalSales;
        }
        
        let sortWeekDays = weekDays.reverse()
        console.log("dateee", sortWeekDays);

        let LINECHART: any = document.getElementById('linechart');
        let lineChart = new Chart(LINECHART, {
            type: 'line',
            options: {
                responsive: true,
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            display: true
                        }
                    }],
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: true
                        }
                    }]
                }
            },
            data: {
                labels: sortWeekDays,
                
                datasets: [
                    {
                        label: 'Order Completed',
                        data: completedOrders.reverse(),
                        fill: false,
                        backgroundColor: '#007bff',
                        borderColor: '#007bff',
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Total Sales',
                        data: totalSales.reverse(),
                        fill: false,
                        backgroundColor: '#fd7e14',
                        borderColor: '#fd7e14',
                        datalabels: {
                            display: false
                        }
                    }    
                ]
            }
        });       
    }

    render() {
        return <canvas ref="chart" id="linechart" />;
    }    
}

class PieChartSos extends Component<{ data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let dataa: any = [];
        let avgPendnig = nextProps.data.avgPendingTime ? nextProps.data.avgPendingTime : "";
        let avgKitchen = nextProps.data.avgKitchenTime ? nextProps.data.avgKitchenTime : "";
        let avgDispatch = nextProps.data.avgDispatchTime ? nextProps.data.avgDispatchTime : "";
        dataa.push(avgPendnig, avgKitchen, avgDispatch)
        console.log("dataa pieee", dataa);
        
        let PIECHART: any = document.getElementById('piechart');
        new Chart(PIECHART, {
            type: 'pie',
            plugins: [ChartDataLabels],
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'SPEED OF SERVICES'
                }
            },
            data: {
                labels: ["Pending", "In Kitchen","Dispatch"],
                datasets: [
                    {
                        // label: 'SOS Pie Chart',
                        data: dataa,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)'
                          ],
                        //   hoverOffset: 4,
                          datalabels: {
                            color: 'white',
                            font: {
                                size: 16,
                                family: 'cursive'
                            }
                        }  
                    }]
            }
        });
    }
    render() {
        return (
        <div>
            <canvas id="piechart" />
        </div>
        );
    }
}

class DougnutChart extends Component<{ data: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let dataa: any = [];
        let mobile = nextProps.data && nextProps.data.mobile ? nextProps.data.mobile : "";
        let web = nextProps.data && nextProps.data.web ? nextProps.data.web : "";
        let callCenter = nextProps.data && nextProps.data.callCenter ? nextProps.data.callCenter : "" ;
        dataa.push(web , mobile , callCenter)
        console.log("dataa daughnut", dataa);
        
        let DAUGHNUT: any = document.getElementById('doughnutchart');
        new Chart(DAUGHNUT, {
            type: 'doughnut',
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'SALES BY CHANNEL'
                }
            },
            data: {
                labels: ["Web", "Mobile","Call Center"],
                datasets: [
                    {
                        label: 'Doughnut Chart',
                        data: dataa,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)'
                          ],
                        //   hoverOffset: 4,
                          datalabels: {
                            formatter: (num) => {
                                if(num > 999 && num < 1000000){
                                    return (num/1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
                                }else if(num > 1000000){
                                    return (num/1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million 
                                }else if(num > 1000000000){
                                    return (num/1000000000).toFixed(0) + 'B'; // convert to B for number from > 1 billion 
                                }
                                else if(num < 900){
                                    return num; // if value < 1000, nothing to do
                                }
                            }, 
                            color: 'white',
                            //rotation: 60,
                            font: {
                                size: 14,
                                family: 'cursive'
                            }
                        } 
                    }]
            }
        });
    }
    render() {
        return (
        <div>
            <canvas id="doughnutchart" />
        </div>
        );
    }
}

class Home extends Component<HomeProps, { days?: any, startDate: any, endDate: any,validTimeFlag:any, [x: number]: any }, {}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            days: 0,
            startDate: "",
            endDate: "",
            validTimeFlag:""
        }
        this.handleDaysFilter = this.handleDaysFilter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidTime = this.handleValidTime.bind(this)
    }
    componentDidMount() {
        let obj = { days: this.state.days };

        this.props.homeCounter(obj);
        this.props.monthlyOrdersForLine();
        this.props.monthlySalesForBar();
        this.props.recentOrders(obj);
        this.props.customersList(obj);
        this.props.LineChartData();
        this.props.PieChartSOS();
        this.props.DougnutChartChannel();
        document.title = "SimplexHDS | Home"
    }
    handleDaysFilter(event: { target: { name: any; value: any; }; }) {
        // this.setState({
        //     [event.target.name]: event.target.value,
        // }); 
        let obj = { days: event.target.value };
        this.props.homeCounter(obj);
        this.props.monthlyOrdersForLine();
        this.props.monthlySalesForBar();
        this.props.recentOrders(obj);
        this.props.customersList(obj);
        // console.log("event.target.value",event.target.value);
    }
    handleValidTime(event: { target: { name: any; value: any; }; }) {
        let futureDatetime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (futureDatetime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
        } else {
            this.setState({ validTimeFlag: false })
            toast.error("Invalid date and time selected");
        }
    }
    isStatsDateReady = () => {
        let { startDate, endDate,validTimeFlag } = this.state;
        return (startDate !== "" && endDate !== ""&& validTimeFlag == true);
    }
    handleSubmit() {
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        }
        this.props.homeCounter(data);
        this.props.recentOrders(data);
        this.props.customersList(data);
    }
    render() {
        let roleId: any = ""
        const { counter, monthlyOrders, lineChart, dougnutChartChannel, pieChartSos, monthlySales, paymentData, orderData, itemsData, customers } = this.props;
        //console.log("Avg time: ", counter);
        console.log(itemsData)
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                } else {
                    roleId = decoded.role_id
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
        let totalSales: any = 0;
        for (let i = 0; i < monthlySales.length; i++) {
            totalSales += parseInt(monthlySales[i].totalSales)
        }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                            <div className="row py-2">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <h4 className="mt-2">Dashboard</h4>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        id="start"
                                                        type="datetime-local"
                                                        name="startDate"
                                                        max={maxDate}
                                                        value={this.state.startDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input
                                                        id="end"
                                                        type="datetime-local"
                                                        name="endDate"
                                                        max={maxDate}
                                                        value={this.state.endDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                                <button disabled={!this.isStatsDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Dashboard</h4>
                                    <select name="days" id="days" onChange={this.handleDaysFilter} style={{ padding: '0 15px' }}>
                                        <option value="0">Today</option>
                                        <option value="1">Yesterday</option>
                                        <option value="7">7 Days</option>
                                        <option value="15">15 Days</option>
                                        <option value="30">30 Days</option>
                                        <option value="60">60 Days</option>
                                        <option value="90">90 Days</option>
                                    </select>

                                </div> */}
                            </div>
                        </header>
                        {/* <!-- Dashboard Counts Section--> */}
                        <section className="dashboard-counts no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow">
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 2 || roleId === 7) ?
                                                <Link to="/orders">
                                                    <div className="icon bg-orange"><i className="fa fa-shopping-cart"></i></div>
                                                </Link>
                                                : <div className="icon bg-orange"><i className="fa fa-shopping-cart"></i></div>
                                            }
                                            <div className="title"><span>Total Sales</span>
                                                <br /> <strong className="text-bold text-dark">{Math.round(counter.totalSales)}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.totalSales / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.totalSales}</strong></div> */}
                                        </div>
                                    </div>


                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 3 || roleId === 4 || roleId === 7) ?
                                                <Link to="/orders">
                                                    <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                                </Link>
                                                : <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            }
                                            <div className="title"><span>Ticket Avg </span>
                                                <br /><strong className="text-bold text-dark">{counter.avgTicket ? parseFloat(counter.avgTicket).toFixed(2) : ''}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.avgTicket / 100}%`, height: "4px" }} className="progress-bar bg-red"></div>   {/*ariaValuenow="70" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.avgTicket}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 4 || roleId === 7) ?
                                                <Link to="/payments">
                                                    <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                                </Link>
                                                : <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            }
                                            <div className="title"><span>Delivery Sales</span>
                                                <br /> <strong className="text-bold text-dark">{Math.round(counter.deliverySales)}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.deliverySales / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.deliverySales}</strong></div> */}
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-sm-6" style={{ maxWidth: "23%" }}>
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 4 || roleId === 7) ?
                                                <Link to="/customers">
                                                    <div className="icon bg-violet"><i className="fa fa-users"></i></div>
                                                </Link>
                                                : <div className="icon bg-violet"><i className="fa fa-users"></i></div>
                                            }
                                            <div className="title"><span>Pickup <br /> Sales</span>
                                                <br /> <strong className="text-bold text-dark">{Math.round(counter.pickupSales)}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.pickupSales / 100}%`, height: "4px" }} className="progress-bar bg-violet"></div>
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.pickupSales}</strong></div> */}
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            {(roleId === 1 || roleId === 4 || roleId === 7) ?
                                                <Link to="/customers">
                                                    <div className="icon bg-red"><i className="fa fa-clock-o"></i></div>
                                                </Link>
                                                : <div className="icon bg-red"><i className="fa fa-clock-o"></i></div>
                                            }
                                            <div className="title"><span>Avg Delivery <br /> Time</span>
                                                <br /> <strong className="text-bold text-dark">{counter.avg_delivery_time ? counter.avg_delivery_time : 0}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.avg_delivery_time / 100}%`, height: "4px" }} className="progress-bar bg-red"></div>  {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{counter.avg_delivery_time}</strong></div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        {/* <!-- Dashboard Header Section    --> */}
                        <section className="dashboard-header  no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <!-- Statistics --> */}
                                    <div className="statistics col-lg-3 col-12">
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.totalOrders}</strong><br /><small>Total Orders </small></div>
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-orange"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.processingOrders}</strong><br /><small>Orders Processing </small></div>
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-green"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.completeOrders}</strong><br /><small>Order Completed</small></div>
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.cancelOrders}</strong><br /><small>Orders Cancelled</small></div>
                                        </div>
                                    </div>
                                    {/* <!-- Line Chart --> */}
                                    <div className="chart col-lg-6 col-12">
                                        <div className="line-chart bg-white d-flex align-items-center justify-content-center has-shadow">
                                            <LineChart data={lineChart} />
                                        </div>
                                    </div>
                                    <div className="chart col-lg-3 col-12">

                                        {/* <!-- Bar Chart   --> */}
                                        <div className="bar-chart has-shadow bg-white">
                                            <div className="title"><strong>{totalSales / 100}%</strong><br /><small>Total Monthly Sales</small></div>
                                            {/* <PieChartSOS data={pieChartSos} /> */}
                                        </div>
                                        <div className="statistic d-flex align-items-center bg-white has-shadow">
                                            <Link to="/orders">
                                                <div className="icon bg-green"><i className="fa fa-clipboard"></i></div>
                                            </Link>
                                            <div className="text"><strong>{counter.totalTransaction}</strong><br /><small>Total Payments</small></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        {/* Pie And Daughnut Charts */}
                        <section className="dashboard-header py-3">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="chart col-lg-6 col-12">
                                        <div className="pie-chart has-shadow bg-white">
                                            <PieChartSos data={pieChartSos} />
                                            <div className="p-2">
                                                <small className="p-4">Average time in minutes</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chart col-lg-6 col-12">
                                        <div className="doughnut-chart has-shadow bg-white">
                                            <DougnutChart data={dougnutChartChannel} />
                                            <div className="p-2">
                                                <small className="p-4">Total sales in rupees</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="dashboard-counts no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow">
                                    {/* <!-- Item --> */}
                                    <div className="col-md-4 d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="statistic d-flex align-items-center">
                                                <div className="icon bg-green"><i className="fa fa-dollar"></i></div>
                                                <div className="text"><strong>{Math.round(counter.salesOfDay)}</strong><br /><small>Daily Sales</small></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-md-4 d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="statistic d-flex align-items-center">
                                                <div className="icon bg-green"><i className="fa fa-dollar"></i></div>
                                                <div className="text"><strong>{Math.round(counter.salesOfWeek)}</strong><br /><small>Weekly Sales</small></div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Item --> */}
                                    <div className="col-md-4 d-flex">
                                        <div className="d-flex align-items-center">
                                            <div className="statistic d-flex align-items-center">
                                                <div className="icon bg-green"><i className="fa fa-dollar"></i></div>
                                                <div className="text"><strong>{Math.round(counter.salesOfMonth)}</strong><br /><small>Monthly Sales</small></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <br />
                        {/* <!-- Feeds Section--> */}
                        <section className="feeds no-padding-top no-padding-bottom">
                            <div className="container-fluid">
                                <div className="row">
                                    {/* <!-- Latest Orders--> */}
                                    <div className="col-lg-7">
                                        <div className="articles card">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <strong>Latest Orders</strong>
                                                {(roleId === 1 || roleId === 3 || roleId === 4 || roleId === 7) &&
                                                    <ul className="mb-0">
                                                        <Link to="/orders"><button className="btn btn-primary p-1">View All Orders</button></Link>
                                                    </ul>
                                                }
                                            </div>
                                            <div className="card-body">
                                                {orderData.length > 0 ? <div className="table-responsive">
                                                    <table className="table table-striped table-hover m-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Order</th>
                                                                <th>Customer</th>
                                                                <th>Phone</th>
                                                                <th>Payment Method</th>
                                                                <th>Price ({currency})</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orderData.map((data: any) => (
                                                                <tr key={data.order_id}>
                                                                    <td>{data.order_id}</td>
                                                                    <td>{data.login_name}</td>
                                                                    <td>{data.phone_number}</td>
                                                                    <td>{data.payment_method}</td>
                                                                    <td>{(data.aggregator_orderId && data.aggregator_orderId > 0) ?
                                                                        data.order_grossprice : Math.round(data.order_grossprice)
                                                                    }</td>
                                                                    <td className="text-capitalize"><span {...(data.order_status_code === 1 && { className: "badge badge-primary p-1" }) || (data.order_status_code === 2 && { className: "badge badge-info p-1" }) || (data.order_status_code === 3 && { className: "badge badge-secondary p-1" }) || ((data.order_status_code === 4 || data.order_status_code === 5) && { className: "badge badge-success p-1" }) || (data.order_status_code === 6 && { className: "badge badge-danger p-1" }) || (data.order_status_code === 7 && { className: "badge badge-secondary p-1" })}>{data.order_status_description}</span></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div> : <p className="text-center my-3">No Records Found</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5">
                                        <div className="articles card">
                                            <div className="card-header d-flex align-items-center">
                                                <h2 className="h3">Top Customers   </h2>

                                                <div className="badge badge-rounded bg-green">
                                                    {customers.length > 0 && `${customers.length} new`}
                                                </div>

                                            </div>
                                            {customers.map((object: any, index: any) => (
                                                <div className="card-body no-padding">
                                                    <div className="item d-flex align-items-center">
                                                        <div className="image"><img
                                                            src={object.profile_pic == "" || object.profile_pic == null ? process.env.PUBLIC_URL + "/assets/img/pic1.png" : `${API_URL}${object.profile_pic}`}
                                                            alt="" className="img-fluid rounded-circle"
                                                            width="50px"
                                                            height="55px"
                                                        /></div>
                                                        <div className="text"><a href="#">
                                                            <h3 className="h5">{object.first_name}{object.last_name}</h3>
                                                        </a>
                                                            <small>{object.phone_number} </small>
                                                            <br />
                                                            <small>{object.email_address} </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>


                                    {/* <!-- Check List --> */}
                                    {/*<div classNameName="col-lg-5">
                                        <div className="daily-feeds card">
                                            <div className="card-header">
                                                <strong>Top Customers</strong>
                                            </div>
                                            <div className="item d-flex align-items-center">
                                    
                                                {customers.map((object : any, index : any) => (
                                                    <div className="item d-flex align-items-center">
                                                      <div className="image"><img src={`https://yum.simplexts.net:3000/${customers.profile_pic}`} alt="..." className="img-fluid rounded-circle"/></div>
                                                      <div className="text"><a href="#">
                                                <h3 className="h5">{customers.first_name }{customers.last_name }</h3></a><small><br/>Posted on 5th June 2017 by Aria Smith.   </small>
                                                        </div>
                                                    <br/>
                                                    </div>
                                                
                                                ))}                                             
                                            </div>   
                                        </div>
                                    </div>
                                    */}
                                    {/*<iframe src="https://www.easymapmaker.com/map/a0016927de8d617214c4d1fa12c2b510" height="400" width="400" title="Iframe Example"></iframe>*/}
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        monthlyOrders: state.home.monthlyOrders,
        monthlySales: state.home.monthlySales,
        counter: state.home.counter,
        paymentData: state.home.paymentData,
        orderData: state.home.orderData,
        itemsData: state.home.itemsData,
        customers: state.home.customerData,
        lineChart: state.home.lineChart,
        dougnutChartChannel: state.home.dougnutChartChannel,
        pieChartSos: state.home.pieChartSos,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        DougnutChartChannel: function () {
            dispatch(DougnutChartChannel())
        }, 
        LineChartData: function () {
            dispatch(LineChartData())
        },
        PieChartSOS: function () {
            dispatch(PieChartSOS())
        },
        homeCounter: function (days: any) {
            dispatch(homeCounter(days))
        },
        monthlyOrdersForLine: function () {
            dispatch(monthlyOrdersForLine())
        },
        monthlySalesForBar: function () {
            dispatch(monthlySalesForBar())
        },
        recentOrders: function (days: any) {
            dispatch(recentOrders(days))
        },
        customersList: function (days: any) {
            dispatch(customersList(days))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);